
import Avatar from "@/components/base/Avatar"

export default {
    name: "Person",
    components: { Avatar },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
}
