
export default {
    name: "Avatar",
    props: {
        /** Avatar sizes */
        size: {
            type: [Number, String],
            default: 70,
        },
        /** Image src, allows array of images */
        src: {
            type: [String, Array, Object],
            default: "",
        },
        /** Add placeholder for empty values */
        placeholder: {
            type: Boolean,
            default: false,
        },
        /** Makes images contained */
        contain: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        imgSrcs() {
            let imgs = this.src
            if (!Array.isArray(imgs)) imgs = [imgs]
            if (!this.placeholder) imgs = imgs.filter(Boolean)

            return imgs.map((img) => {
                if (typeof img === "string") return img
                return this.$getImage({
                    image: img,
                    preferedSize: "3",
                }).src
            })
        },
    },
}
